import { FormControl, FormControlLabel, FormHelperText, Grid, Link, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCustomerReason, setSubReason, setCustomerDescription, setDefectBy, setImei, setSelectedAction } from '../../store/slices/orderSlice';

/**
 * Product paper
 */
class ProductPaper extends Component {

  /**
   * Constructor.
   *
   * @param {Object} props - just props.
   */
  constructor(props) {
    super(props);
    this.props = props;

    this.changeReason = this.changeReason.bind(this);
  }

  /**
   * Change the current selected reason
   *
   * @param {Object} value - reason
   */
  changeReason(value) {
    this.props.setCustomerReason({selectedReason: value, item: this.props.item});
  }

  /**
   * Render
   *
   * @return {JSX} - render.
   */
  render() {
    const item = this.props.item;

    return (
      <Paper className="returnReasonBlock" elevation={0} onClick={this.updateCheckbox}>
        <Grid
          container>
          <Grid
            item
            xs={4} md={2}>
            <Paper className="gridPaper" elevation={0}>
              <img alt="productImage" className="image" src={item.imgUrl} />
            </Paper>
          </Grid>
          <Grid item xs={8} className="mobileProductTitle">
            <Typography className="productTitle">{item.name}</Typography>
          </Grid>
          <Grid
            item
            xs={12} md={10}>
            <Grid item xs={12} className="desktopProductTitle">
              <Typography className="productTitle">{item.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="returnReasonTitle">Reden <span className="red">*</span></Typography>
              <FormControl variant="outlined" className="returnDataDropdown" sx={{mt: 10}}>
                {/* <InputLabel id="demo-simple-select-label">Reden</InputLabel> */}
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id={`reasonSelect-${this.props.unique}`}
                  displayEmpty
                  onChange={(e) => this.changeReason(e.target.value)}
                  error={item.customerReasonError !== undefined}
                  required
                >
                  {this.props.reasons && this.props.reasons.map((reason, i) => {
                    if (!item.inRetractionPeriod && !reason.allowedOutsideRetractionPeriod) {
                      return false;
                    }
                    return <MenuItem id={`reasonSelect-reason-${i}`} key={i} value={reason}>{reason.displayName}</MenuItem>;
                  })}
                </Select>
                {item.customerReasonError !== undefined && <FormHelperText className="helperTextError">{item.customerReasonError}</FormHelperText>}
              </FormControl>

              <Grid container>

                {item.customerReason !== undefined && item.customerReason.possibleSubReasons.length > 0 ?
                  <Grid container>
                    <Typography className="returnReasonTitle">Selecteer reden <span className="red">*</span></Typography>
                    <Grid container>
                      <FormControl component="fieldset">
                        <RadioGroup id="subReasonsToggle" row aria-label="position" name="position" defaultValue="top" value={item.subReason.id} onChange={(e) => this.props.setSubReason({subReason: e.target.value, item: item})}>
                          {item.customerReason.possibleSubReasons.map((reason, i) => {
                            if (!reason.forType.includes(item.type)) {
                              return false;
                            }
                            return <Grid key={i} item xs={12} md={6}>
                              <FormControlLabel
                                control={
                                  <Radio id={`subReasonSelectToggle-${i}`} key={i} color="primary" />
                                }
                                value={'' + reason.id}
                                label={reason.displayName}
                                labelPlacement="end"
                                error={item.subReasonError !== undefined}
                              />
                            </Grid>;
                          })}
                        </RadioGroup>
                        {item.subReasonError !== undefined && <FormHelperText className="helperTextError">{item.subReasonError}</FormHelperText>}
                      </FormControl>
                      {item.subReason && item.subReason.advicePrompt && <Grid item xs="12">
                        <Paper elevation={0} className="alertTryThis">
                          <Grid container direction="row" justify="space-between">
                            <Grid item >
                              <Typography variant="body2" className="alertSecondary">{item.subReason.advicePrompt}</Typography>
                            </Grid>
                            {item.subReason.adviceLink && <Grid item >
                              <Link variant="body2" className="alertAction" href={item.subReason.adviceLink}>Lees meer</Link>
                            </Grid>}
                          </Grid>
                        </Paper>
                      </Grid>}
                    </Grid>
                  </Grid>
                  :
                  null}

                {item.customerReason !== undefined && <Grid item xs={12}>
                  <Typography className="returnReasonTitle">Toelichting {!item.inRetractionPeriod && <span className="red">*</span>}</Typography>
                  <TextField
                    onChange={(e) => this.props.setCustomerDescription({item, customerDescription: e.target.value})}
                    id={`reasonDescription-${this.props.unique}`}
                    className="returnDataDropdown"
                    multiline
                    rows={4}
                    variant="outlined"
                    value={item.customerDescription}
                    error={item.customerDescriptionError !== undefined}
                    helperText={item.customerDescriptionError}
                  />
                </Grid>}

                {item.subReason !== undefined && item.subReason.showCustomerFault ?
                  <Grid item xs={12}>
                    <Typography className="returnReasonTitle">Is het defect door eigen toedoen? <span className="red">*</span></Typography>

                    <RadioGroup id={`reasonDefectToggle-${this.props.unique}`} row aria-label="position" name="position" value={parseInt(item.defectBy)} onChange={(e) => this.props.setDefectBy({item, defectBy: e.target.value})}>
                      <FormControlLabel
                        value={1}
                        control={<Radio color="primary" />}
                        label="Ja"
                        labelPlacement="end"
                        error={item.defectByError !== undefined}
                        id="ja"
                      />
                      <FormControlLabel
                        value={0}
                        control={<Radio color="primary" />}
                        label="Nee"
                        labelPlacement="end"
                        error={item.defectByError !== undefined}
                        id="nee"
                      />
                    </RadioGroup>
                    {item.defectByError !== undefined && <FormHelperText className="helperTextError">{item.defectByError}</FormHelperText>}
                  </Grid>
                  :
                  null
                }

                {item.type === 'refurbished' ?
                  <Grid item xs={12}>
                    <div className="imeiTitlesWrapper">
                      <Typography className="imeiTitle">IMEI of serienummer van het device {<span className="red">*</span>}</Typography>
                      <Typography className="imeiExplain"><Link href="https://support.apple.com/nl-nl/HT204073" target="_blank">Waar je dit kan vinden</Link></Typography>
                    </div>
                    <FormControl variant="outlined" className="returnDataDropdown">
                      <TextField
                        onChange={(e) => this.props.setImei({item, imei: e.target.value})}
                        id={`reasonImei-${this.props.unique}`}
                        className="returnDataDropdown"
                        variant="outlined"
                        value={item.imei}
                        error={item.serialError !== undefined}
                        helperText={item.serialError}
                      />
                    </FormControl>
                  </Grid>
                  :
                  null}

                {item.customerReason !== undefined && <Grid item xs={12}>
                  <Typography className="returnReasonTitle">Gewenste actie <span className="red">*</span></Typography>
                  <FormControl variant="outlined" className="returnDataDropdown">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id={`reasonAction-${this.props.unique}`}
                      value={'' + item.selectedAction}
                      onChange={(e) => this.props.setSelectedAction({item, selectedAction: e.target.value})}
                      error={item.selectedActionError !== undefined}
                    >
                      {item.customerReason !== undefined ? item.customerReason.possibleActions.map((action, i) => {
                        if (!item.inRetractionPeriod && !item.customerReason.allowedOutsideRetractionPeriod) {
                          return false;
                        }
                        return <MenuItem id={`reasonActionSelect-${i}`} key={i} value={action.id}>{action.displayName}</MenuItem>;
                      })
                        : null}
                    </Select>
                    {item.selectedActionError !== undefined && <FormHelperText className="helperTextError">{item.selectedActionError}</FormHelperText>}
                  </FormControl>
                </Grid>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}


const mapStateToProps = (state) => ({
  reasons: state.rma.reasons,
});

const mapDispatchToProps = { setCustomerReason, setSubReason, setCustomerDescription, setDefectBy, setImei, setSelectedAction };
export default connect(mapStateToProps, mapDispatchToProps)(ProductPaper);

