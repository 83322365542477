import React, { Component } from 'react';
import { Container, Grid, Typography, Paper, Button } from '@material-ui/core';
import './index.css';
import ProductPaper from '../../components/rmaReasons/productPaper';
import { connect } from 'react-redux';
import { getReasons, publishRMA } from '../../store/slices/rmaSlice';
import { setErrorMessages } from '../../store/slices/orderSlice';
import { setPage } from '../../store/slices/pageSlice';

/**
 * RMA reasons screen
 *
 * @version 1.0.0
 */
class RmaReasonsScreen extends Component {

  /**
   * Constructor
   *
   * @param {Object} props - props
   * @version 1.0.0
   */
  constructor(props) {
    super(props);
    this.props = props;

    this.continue = this.continue.bind(this);
  }

  /**
   * On mount component
   */
  componentDidMount() {
    this.props.getReasons();
    window.scrollTo(0, 0);
  }

  /**
   * Send RMA to backend.
   */
  continue() {

    const messages = [];

    this.props.order.items.forEach((item, key) => {

      if (!item.checked) {
        return;
      }

      if ((item.customerReason === undefined || item.customerReason === '')) {
        messages.push({item, errorField: 'customerReason', errorMessage: 'Selecteer een reden'});
      }

      if ((item.customerDescription === undefined || item.customerDescription === '') && !item.inRetractionPeriod) {
        messages.push({item, errorField: 'customerDescription', errorMessage: 'Vul een toelichting in'});
      }

      if ((item.subReason === undefined || !item.subReason) && item.customerReason !== undefined && item.customerReason.possibleSubReasons !== undefined && item.customerReason.possibleSubReasons.length > 0) {
        messages.push({item, errorField: 'subReason', errorMessage: 'Selecteer een reden'});
      }

      if ((item.defectBy === undefined || !item.defectBy) && item.subReason !== undefined && item.subReason.showCustomerFault) {
        messages.push({item, errorField: 'defectBy', errorMessage: 'Selecteer een optie'});
      }

      if ((item.imei === undefined || !item.imei) && item.type === 'refurbished') {
        messages.push({item, errorField: 'serial', errorMessage: 'Vul je IMEI of serienummer in'});
      }

      if (item.imei !== undefined && !(item.imei.length === 12 || item.imei.length === 15) && item.type === 'refurbished') {
        messages.push({item, errorField: 'serial', errorMessage: 'Vul geldig IMEI of serienummer in'});
      }

      if (item.selectedAction === undefined || !item.selectedAction) {
        messages.push({item, errorField: 'selectedAction', errorMessage: 'Selecteer een gewenste actie'});
      }
    });

    this.props.setErrorMessages(messages);

    if (!messages.length) {
      this.props.publishRMA({rma: this.props.order, email: this.props.email});
    } else {
      window.scrollTo(0, 0);
    }
  }

  /**
   * Check if the order has any errors
   * @param {Object} order the order to check
   * @return {bool} if has errors
   */
  getErrorMessages(order) {
    for (const item of order.items) {
      if (item.serialError || item.selectedActionError || item.customerReasonError || item.defectByError || item.customerDescriptionError || item.subReasonError) {
        return true;
      }
    }

    return false;
  }

  /**
   * Renderer
   *
   * @return {JSX} - the to render JSX.
   * @version 1.0.0
   */
  render() {

    const order = this.props.order;

    return (
      <React.Fragment>
        <Container>
          <Grid container justify="center">
            <Grid item xs={12} lg={8}>
              <Grid container>
                <Grid item xs="12">
                  <Paper onClick={this.props.prevStep} elevation={0}>
                    <Typography id="return-reason-back" className="backText" onClick={() => this.props.setPage(0)}>Terug</Typography>
                  </Paper>
                  <Typography variant="h6" gutterBottom id="return-reason" className="pageTitle">Aanvullende informatie</Typography>
                  <Typography variant="body1" gutterBottom>
                    Selecteer per artikel de reden van retourneren.
                  </Typography>
                  {this.getErrorMessages(order) && <Typography style={{padding: 10, borderRadius: 6, backgroundColor: '#ffe6e8', border: '1px solid #ffc3c7', paddingLeft: 20}}>Graag alle verplichte velden invullen</Typography>}
                </Grid>

                <Grid item xs="12">
                  {order.items && order.items.map((item, i) => {
                    if (item.checked) {
                      return (
                        <ProductPaper key={i} unique={i} item={item} />
                      );
                    }
                    return null;
                  })}

                </Grid>

                <Button id="continue" xs={4} variant="contained" className="confirmButton" onClick={this.continue}>
                  Retour indienen
                </Button>
              </Grid>
            </Grid>

          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.orders.order,
  rma: state.rma,
  email: state.search.email,
});

const mapDispatchToProps = { getReasons, publishRMA, setPage, setErrorMessages };

export default connect(mapStateToProps, mapDispatchToProps)(RmaReasonsScreen);
