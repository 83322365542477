import { combineReducers } from 'redux';
import orderSlice from './orderSlice';
import pageSlice from './pageSlice';
import searchSlice from './searchSlice';
import rmaSlice from './rmaSlice';

const reducers = combineReducers({
  orders: orderSlice,
  search: searchSlice,
  page: pageSlice,
  rma: rmaSlice,
});

export default reducers;
