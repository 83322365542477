import React, { Component } from 'react';
import { Stepper, Step, StepLabel, withStyles, StepConnector } from '@material-ui/core';
import './style.css';
import NoteIcon from '@material-ui/icons/Note';
import InboxIcon from '@material-ui/icons/Inbox';
import CheckIcon from '@material-ui/icons/Check';
import PersonIcon from '@material-ui/icons/Person';
import { connect } from 'react-redux';
import { setPage } from '../../store/slices/pageSlice';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 18,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#049eea',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#049eea',
    },
  },
  line: {
    borderColor: '#e7e7e7',
    borderTopWidth: 10,
    borderRadius: 1,
    zIndex: 0,
  },
})(StepConnector);

/**
 * Stepper class.
 *
 * @version 1.0.0
 */
class MainStepper extends Component {


  /**
   * Constructor
   *
   * @param {Object} props - props
   * @version 1.0.0
   */
  constructor(props) {
    super(props);

    this.state = {
      stepper: [
        {
          label: 'Je bestelling',
          icon: PersonIcon,
          index: 0,
        },
        {
          label: 'Reden',
          icon: NoteIcon,
          index: 1,
        },
        {
          label: 'Aanmelding ontvangen',
          icon: InboxIcon,
          index: 2,
        },
        {
          label: 'Verwerkt',
          icon: CheckIcon,
          index: 3,
        },
      ],
    };

    this.getActiveStep = this.getActiveStep.bind(this);
  }

  /**
   * Set the current step
   *
   * @param {Number} index - go to the given step
   * @version 1.0.0
   */
  stepTo(index) {
    this.props.setStep(index);
  }


  /**
   * Get current step
   *
   * @return {Number} the step
   */
  getActiveStep() {
    if (this.props.page <= 2 && !this.props.gatekeeping && !this.props.publishedRma) {
      return this.props.page;
    } else if (this.props.gatekeeping) {
      return 2;
    } else if (this.props.publishedRma) {
      return 3;
    }

    return 0;
  }


  /**
   * Renderer
   *
   * @return {JSX} - the to render JSX.
   * @version 1.0.0
   */
  render() {
    return (
      <React.Fragment>
        <Stepper alternativeLabel activeStep={this.getActiveStep()} className="root" connector={<QontoConnector />}>
          {this.state.stepper.map((step) => {
            const stepClass = 'step';
            return (
              <Step key={step.label} className={stepClass}>
                <StepLabel StepIconComponent={step.icon} className="stepLabel"></StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state) => ({
  order: state.orders.order,
  page: state.page.page,
  gatekeeping: state.rma.gatekeeping,
  publishedRma: state.rma.publishedRma,
});

const mapDispatchToProps = { setPage };

export default connect(mapStateToProps, mapDispatchToProps)(MainStepper);
