import React, { Component } from 'react';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import './style.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { setOrderId, setEmail, setCaptcha, setEmailError, setCaptchaError, setOrderIdError } from './../../../store/slices/searchSlice';
import { getOrder } from './../../../store/slices/orderSlice';

const recaptchaRef = React.createRef();

/**
 * Search order field component
 *
 * @version 1.0.0
 */
class SearchOrderFields extends Component {

  /**
   * Constructor
   *
   * @param {Object} props - props
   * @version 1.0.0
   */
  constructor(props) {
    super(props);

    this.fetchOrder = this.fetchOrder.bind(this);
    this.getDisabled = this.getDisabled.bind(this);
  }

  /**
   * Get disabled
   *
   * @return {boolean} - false when disabled, true when enabled.
   * @version 1.0.0
   */
  getDisabled() {
    return false;
    // return this.state.emailErrorMessage || this.state.orderIdErrorMessage || this.state.captcha === undefined;
  }

  /**
   * Get full order
   * @version 1.0.0
   */
  fetchOrder() {

    // const canSave = this.props.orderId && this.props.email && this.props.captcha && this.props.loading === 'idle'
    const canSave = this.props.orderId && this.props.email && (this.props.loading === 'idle' || this.props.loading === 'rejected');

    const data = {
      orderId: this.props.orderId,
      email: this.props.email,
      captcha: this.props.captcha,
      recaptchaRef: recaptchaRef,
    };

    if (canSave) {
      this.props.getOrder(data);
    } else {
      if (!this.props.orderId) {
        this.props.setOrderIdError('Je moet een ordernummer invullen');
      }

      if (!this.props.email) {
        this.props.setEmailError('Je moet een emailadres invullen');
      }

      if (!this.props.captcha) {
        this.props.setCaptchaError('Accepteer de Captcha');
      }
    }
  }


  /**
   * Renderer
   *
   * @return {JSX} - the to render JSX.
   * @version 1.0.0
   */
  render() {
    return (
      <React.Fragment>
        <Grid item container xs={12} lg={8} justify="space-between" spacing={2}>
          <Grid item xs={12}>
            {this.props.error && <Alert severity="error" className="alertBox">{this.props.fetchErrorMessage}</Alert>}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField id="order-nr" label="Ordernummer" variant="outlined" className="textfield" placeholder="Bijv. #420369" value={this.props.orderId} onChange={(e) => this.props.setOrderId(e.target.value)} error={this.props.orderIdError} helperText={this.props.orderIdErrorMessage}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField id="email" label="E-mailadres" variant="outlined" className="textfield" value={this.props.email} onChange={(e) => this.props.setEmail(e.target.value)} error={this.props.emailError} helperText={this.props.emailErrorMessage}/>
          </Grid>
          <Grid item xs={12} md={6} id="recaptcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LfCKcYZAAAAAEBHq2N60PFhFqewZ0onXzgiEnoO"
              onChange={(value) => this.props.setCaptcha(value)}
            />
            {this.props.captchaError && <Typography className="captchaError">{this.props.captchaErrorMessage}</Typography>}
          </Grid>
          <Grid item xs={12}>
            <Button id="retrieveOrder" variant="contained" className="confirmButton" onClick={this.fetchOrder}>
              Doorgaan
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  orderId: state.search.orderId,
  orderIdError: state.search.orderIdError,
  orderIdErrorMessage: state.search.orderIdErrorMessage,
  email: state.search.email,
  emailError: state.search.emailError,
  emailErrorMessage: state.search.emailErrorMessage,
  captcha: state.search.captcha,
  captchaError: state.search.captchaError,
  captchaErrorMessage: state.search.captchaErrorMessage,
  loading: state.orders.status,
  fetchErrorMessage: state.orders.errorMessage,
  error: state.orders.error,
});

const mapDispatchToProps = { setOrderId, setEmail, setCaptcha, getOrder, setEmailError, setCaptchaError, setOrderIdError };

export default connect(mapStateToProps, mapDispatchToProps)(SearchOrderFields);
