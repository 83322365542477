import React, { Component } from 'react';
import { Container, Grid } from '@material-ui/core';
import StepperComponent from '../../components/stepper';
import './index.css';
import Header from '../../components/header';
import RegisterRMAScreen from '../registerRma';
import RmaReasonsScreen from '../rmaReasons';
import RmaReceived from '../rmaReceived';
import RmaFinish from '../rmaFinish';
import { setPage } from '../../store/slices/pageSlice';
import { connect } from 'react-redux';

/**
 * Main screen.
 *
 * @version 1.0.0
 */
class Main extends Component {

  /**
   * Renderer
   *
   * @return {JSX} - the to render JSX.
   * @version 1.0.0
   */
  render() {
    return (
      <React.Fragment>
        <Header loading={this.props.loading} />
        <Container>
          <Grid container justify={'center'}>
            <Grid item xs={12} lg={8}>
              <StepperComponent/>
            </Grid>
          </Grid>
        </Container>
        {this.props.page === 0 && !this.props.publishedRma && <RegisterRMAScreen/>}
        {this.props.page === 1 && !this.props.publishedRma && <Grid>
          <RmaReasonsScreen/>
        </Grid>}
        {this.props.gatekeeping && this.props.publishedRma && <Grid>
          <RmaReceived />
        </Grid>}
        {this.props.publishedRma && !this.props.gatekeeping && <Grid>
          <RmaFinish />
        </Grid>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  page: state.page.page,
  gatekeeping: state.rma.gatekeeping,
  publishedRma: state.rma.publishedRma,
  loading: state.orders.loading || state.rma.reasonsLoading,
});

const mapDispatchToProps = { setPage };

export default connect(mapStateToProps, mapDispatchToProps)(Main);
