import React, { Component } from 'react';
import './style.css';
import SearchOrderFields from './searchOrderFields';

/**
 * Search order component
 *
 * @version 1.0.0
 */
class OrderSearch extends Component {

  /**
   * Constructor
   *
   * @param {Object} props - props
   * @version 1.0.0
   */
  constructor(props) {
    super(props);
    this.props = props;
  }

  /**
   * Renderer
   *
   * @return {JSX} - the to render JSX.
   * @version 1.0.0
   */
  render() {
    return (
      <React.Fragment>
        <SearchOrderFields/>
      </React.Fragment>
    );
  }
}

export default OrderSearch;
