import React, { Component } from 'react';
import { Container, Grid, Typography} from '@material-ui/core';
import './index.css';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import { connect } from 'react-redux';
import API from '../../lib/api';

dayjs.locale('nl');
/**
 * RMA finish screen
 *
 * @version 1.0.0
 */
class FinishRMAScreen extends Component {

  /**
   * Constructor
   *
   * @param {Object} props - props
   * @version 1.0.0
   */
  constructor(props) {
    super(props);

    this.handlePdfClick = this.handlePdfClick.bind(this);
  }

  /**
   * Open a new window to download the pdf
   */
  handlePdfClick() {
    const url = API.getApiUrl();
    window.open(url + `api/rmas/${this.props.postedRma.id}/pdf/${this.props.postedRma.fileToken}`, '_blank').focus();
  }


  /**
   * Renderer
   *
   * @return {JSX} - the to render JSX.
   * @version 1.0.0
   */
  render() {
    return (
      <React.Fragment>
        <Container>
          <Grid container justify={'center'}>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom className="pageTitle">Retouraanmelding verwerkt</Typography>
              <Typography variant="body1" gutterBottom>
              We hebben je retouraanmeldig verwerkt. Binnen enkele minuten ontvang je per mail een bevestiging met de retourinstructies. Indien je tijdens het bestellen gebruik hebt gemaakt van je account dan is de status van je retour ook te volgen via mijn account.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.orders.order,
  postedRma: state.rma.publishedRma,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(FinishRMAScreen);
