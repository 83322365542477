import { createSlice } from '@reduxjs/toolkit';

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    orderId: '',
    orderIdError: false,
    orderIdErrorMessage: '',
    email: '',
    emailError: false,
    emailErrorMessage: '',
    captcha: undefined,
    captchaError: false,
    captchaErrorMessage: '',
  },
  reducers: {
    setOrderId(state, action) {
      action.payload = action.payload.replace('#', '');
      if (!action.payload.match(/^[0-9]*$/)) {
        state.orderIdError = true;
        state.orderIdErrorMessage = 'Ongeldig ordernummer';
      } else {
        state.orderIdError = false;
        state.orderIdErrorMessage = '';
      }

      state.orderId = action.payload;
    },
    setEmail(state, action) {
      if (!action.payload.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        state.emailError = true;
        state.emailErrorMessage = 'Ongeldig emailadres';
      } else {
        state.emailError = false;
        state.emailErrorMessage = '';
      }

      state.email = action.payload;
    },
    setCaptcha(state, action) {
      state.captchaError = false;
      state.captchaErrorMessage = '';
      state.captcha = action.payload;
    },
    setEmailError(state, action) {
      state.emailError = true;
      state.emailErrorMessage = action.payload;
    },
    setCaptchaError(state, action) {
      state.captchaError = true;
      state.captchaErrorMessage = action.payload;
    },
    setOrderIdError(state, action) {
      state.orderIdError = true;
      state.orderIdErrorMessage = action.payload;
    },
  },
});

export const { setOrderId, setEmail, setCaptcha, setEmailError, setCaptchaError, setOrderIdError } = searchSlice.actions;
export default searchSlice.reducer;
