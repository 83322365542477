import React, { Component } from 'react';
import { AppBar, Container, Grid, Typography, Link, LinearProgress } from '@material-ui/core';
import './style.css';

/**
 *
 */
class Header extends Component {

  /**
   * Renderer
   *
   * @return {JSX} - the to render JSX.
   * @version 1.0.0
   */
  render() {
    return (
      <React.Fragment>
        <AppBar position="static" className={'header'} elevation={0}>
          <Container>
            <Grid container justify="center">
              <Grid item xs={6} lg={4} className="header-img-wrapper">
                <img src={'https://images.fixje.nl/wp-content/uploads/2023/01/Fixje-liggend-wit.svg'} alt="Fixje logo" />
              </Grid>
              <Grid item xs={6} lg={4} className="header-links">
                <Typography><Link color="secondary" href="https://www.fixje.nl/" className="headerBack">Terug naar fixje.nl</Link></Typography>
              </Grid>
            </Grid>
          </Container>
        </AppBar>
        {this.props.loading && <LinearProgress color="primary" className="loadingBar" />}
      </React.Fragment>
    );
  }
}

export default Header;
