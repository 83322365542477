/* eslint-disable guard-for-in */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import API from '../../lib/api';

export const getOrder = createAsyncThunk('order/fetchOrder', async (data, { rejectWithValue }) => {
  try {
    return await API.getOrder(data.orderId, data.email, data.captcha);
  } catch (err) {
    data.recaptchaRef.current.reset();
    return rejectWithValue(err);
  }
});

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    order: null,
    status: 'idle',
    error: null,
    userCompleted: false,
  },
  reducers: {
    setChecked(state, action) {
      for (const item of state.order.items) {
        if (item.lineId === action.payload.lineId && item.id === action.payload.id) {
          item.checked = !item.checked;
        }
      }
    },
    setCustomerReason(state, action) {
      // eslint-disable-next-line guard-for-in
      for (const item of state.order.items) {
        if (item.lineId === action.payload.item.lineId && item.id === action.payload.item.id) {
          item.customerReason = action.payload.selectedReason;
          item.subReason = false;
          item.defectBy = undefined;
          item.customerReasonError = undefined;
        }
      }
    },
    setSubReason(state, action) {
      for (const item of state.order.items) {
        if (item.lineId === action.payload.item.lineId && item.id === action.payload.item.id) {
          if (item.customerReason.possibleSubReasons.length) {
            item.customerReason.possibleSubReasons.forEach((element) => {
              if (parseInt(action.payload.subReason) === element.id) {
                if (!element.showCustomerFault) {
                  item.defectBy = undefined;
                }
                item.subReason = element;
                item.subReasonError = undefined;
              }
            });
          }
        }
      }
    },
    setCustomerDescription(state, action) {
      for (const item of state.order.items) {
        if (item.lineId === action.payload.item.lineId && item.id === action.payload.item.id) {
          item.customerDescription = action.payload.customerDescription;
          item.customerDescriptionError = undefined;
        }
      }
    },
    setDefectBy(state, action) {
      for (const item of state.order.items) {
        if (item.lineId === action.payload.item.lineId && item.id === action.payload.item.id) {
          item.defectBy = action.payload.defectBy;
          item.defectByError = undefined;
        }
      }
    },
    setImei(state, action) {
      for (const item of state.order.items) {
        if (item.lineId === action.payload.item.lineId && item.id === action.payload.item.id) {
          item.imei = action.payload.imei;
          item.serialError = undefined;
        }
      }
    },
    setSelectedAction(state, action) {
      for (const item of state.order.items) {
        if (item.lineId === action.payload.item.lineId && item.id === action.payload.item.id) {
          item.selectedAction = action.payload.selectedAction;
          item.selectedActionError = undefined;
        }
      }
    },
    setErrorMessages(state, action) {
      for (const message of action.payload) {
        for (const item of state.order.items) {
          if (item.lineId === message.item.lineId && item.id === message.item.id) {
            switch (message.errorField) {
              case ('customerReason'):
                item.customerReasonError = message.errorMessage;
                break;
              case ('customerDescription'):
                item.customerDescriptionError = message.errorMessage;
                break;
              case ('subReason'):
                item.subReasonError = message.errorMessage;
                break;
              case ('defectBy'):
                item.defectByError = message.errorMessage;
                break;
              case ('serial'):
                item.serialError = message.errorMessage;
                break;
              case ('selectedAction'):
                item.selectedActionError = message.errorMessage;
                break;
              default:
                break;
            }
          }
        }
      }
    },
    resetOrder(state, action) {
      state.order = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrder.fulfilled, (state, { payload }) => {
      state.status = 'idle';
      state.loading = false;

      const order = payload;

      if (order && order.items) {
        order.items.forEach((item, index) => {
          order.items[index].checked = false;
          order.items[index].subReason = 0;
          order.items[index].customerDescription = '';
          order.items[index].fieldErrors = [];

          const warrantyExpireDate = dayjs(item.warrantyExpiredDate);
          order.items[index].inWarranty = order.overrideWarranty || warrantyExpireDate > dayjs();
          order.items[index].inRetractionPeriod = order.overrideRetractionPeriod || dayjs(order.dateFinished).add(17, 'day') > dayjs();
        });
        state.order = order;
      }
    });
    builder.addCase(getOrder.pending, (state, action) => {
      state.status = 'loading';
      state.error = false;
      state.loading = true;
      state.order = null;
    });
    builder.addCase(getOrder.rejected, (state, action) => {
      state.status = 'rejected';
      state.loading = false;
      state.order = null;


      if (action.payload.message === 'Not found') {
        state.errorMessage = 'De bestelling kan niet gevonden worden';
        state.error = action.payload.message;
      } else if (action.payload.message === 'Bad response from server') {
        state.errorMessage = 'Zorg dat alle velden goed zijn ingevuld';
        state.error = action.payload.message;
      } else {
        state.errorMessage = 'Er is iets fout gegaan met het ophalen van de bestelling';
        state.error = action.error.message;
      }
    });
  },
});

export const { setChecked, setCustomerReason, setSubReason, setCustomerDescription, setDefectBy, setImei, setSelectedAction, setErrorMessages, resetOrder } = orderSlice.actions;
export default orderSlice.reducer;
