import { applyMiddleware, compose, createStore } from '@reduxjs/toolkit';
import reducers from './slices';
import thunk from 'redux-thunk';
import { persistStore /* , persistReducer */} from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, reducers);
// storage.removeItem('persist:root');

// window.devToolsExtension && window.devToolsExtension(),


export const store = compose(applyMiddleware(thunk))(createStore)(reducers);

export const persistor = persistStore(store);
