
/**
 * Order class
 *
 * @version 1.0.0
 */
class API {

  /**
   * Constructor
   *
   * @version 1.0.0
   */
  constructor() {
    this.apiUrl = 'https://api.retour.fixjeiphone.nl/';

    this.handleErrors = this.handleErrors.bind(this);
  }


  /**
   * Get the api url
   * @return {string} apiUrl
   */
  getApiUrl() {
    return this.apiUrl;
  }


  /**
   * Get order
   *
   * @param {Number} orderID - order id to get
   * @param {String} email - attached email
   * @param {Object} captcha - captcha
   * @version 1.0.0
   */
  async getOrder(orderID, email, captcha) {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };

    return await fetch(`${this.apiUrl}api/orders/${orderID}?email=${email}&captcha=${captcha}`, requestOptions).then((response) => {
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error('Not found');
        }
        throw new Error('Bad response from server');
      }
      return response;
    }).then(async (returnedResponse) => {
      returnedResponse = await returnedResponse.json();
      return returnedResponse;
    });
  }

  /**
   * Get reasons api call.
   *
   * @param {Function} successCallback - Function called on success.
   * @param {Function} errorCallback - Function called on fail.
   * @version 1.0.0
   */
  async getReasons() {
    return await fetch(`${this.apiUrl}api/Reasons`, {
      method: 'GET',
    })
        .then(this.handleErrors)
        .then((res) => res.json())
        .then((data) => {
          return data;
        })
        .catch((error) => {
          this.handleErrors(error);
        });
  }

  /**
   * Send the Rma to the backend.
   *
   * @param {Object} data
   * @version 1.0.0
   */
  async postRma(data) {
    return await fetch(`${this.apiUrl}api/rmas`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    })
        .then(this.handleErrors)
        .then((res) => res.json())
        .then((data) => {
          return data;
        })
        .catch((error) => {
          this.handleErrors(error);
        });
  }

  /**
   * Send the Rma to the backend.
   * @param {integer} rmaId
   * @param {string} fileToken
   * @version 1.0.0
   */
  async getPdf(rmaId, fileToken) {
    return await fetch(`${this.apiUrl}api/rmas/${rmaId}/pdf/${fileToken}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    })
        .then(this.handleErrors)
        .then((data) => {
          return data;
        })
        .catch((error) => {
          this.handleErrors(error);
        });
  }

  /**
   * Handle errors.
   * @param {Object} response - Response.
   * @version 1.0.0
   * @return {Object} - response
   */
  handleErrors(response) {
    if (!response.ok) {
      // eslint-disable-next-line no-console
      console.error('!An API error occurred!');
      // eslint-disable-next-line no-console
      throw response;
    } else {
      return response;
    }
  }
}

export default (new API());
