import React, { Component } from 'react';
import { Container, Grid, Typography, Button, Link } from '@material-ui/core';
import './index.css';

/**
 * Received RMA screen
 *
 * @version 1.0.0
 */
class ReceivedRMAScreen extends Component {

  /**
   * Renderer
   *
   * @return {JSX} - the to render JSX.
   * @version 1.0.0
   */
  render() {
    return (
      <React.Fragment>
        <Container>
          <Grid container justify="center">
            <Grid item xs={12} lg={8}>
              <Typography variant="h6" gutterBottom className="pageTitle">Retouraanmelding ontvangen</Typography>
              <Typography variant="body1" gutterBottom>We hebben je retouraanmelding ontvangen. Op werkdagen nemen we binnen 24 uur contact met je op. Indien je tijdens het bestellen gebruik hebt gemaakt van je account dan is de status van je retour ook te volgen via mijn account.</Typography>

              <Grid item xs={5}>
                <Button variant="contained" color="primary" className="confirmButton sendItemBtn">
                  <Link href="https://www.fixjeiphone.nl/mijn-account/" color="inherit">
                    Naar Mijn account
                  </Link>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

export default ReceivedRMAScreen;
