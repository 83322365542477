import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../lib/api';

export const getReasons = createAsyncThunk('rma/fetchReasons', async (data, { rejectWithValue }) => {
  try {
    return await API.getReasons();
  } catch (err) {
    return rejectWithValue(err);
  }
});


export const publishRMA = createAsyncThunk('rma/publishRMA', async (data, { rejectWithValue }) => {
  try {
    const order = {
      wooOrderId: data.rma.id,
      email: data.email,
      rmaItems: [],
    };

    data.rma.items.forEach((item, i) => {
      if (!item.checked) {
        return;
      }
      order.rmaItems.push({
        wooItemId: item.lineId,
        customerReason: { id: item.customerReason.id },
        customerSubReasons: item.subReason.id ? [{ id: item.subReason.id }] : [],
        customerDescription: item.customerDescription.trim() ? item.customerDescription.trim() : null,
        customerAtFault: item.defectBy === '1' ? true : false,
        returnProductSerialNumber: item.imei || null,
        customerWishedAction: { id: item.selectedAction },
      });
    });


    return await API.postRma(order);
  } catch (err) {
    return rejectWithValue(err);
  }
});


const rmaSlice = createSlice({
  name: 'rma',
  initialState: {
    reasons: [],
    publishedRma: false,
    reasonsStatus: 'idle',
    reasonsLoading: false,
    publishLoading: false,
    gatekeeping: false,
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(getReasons.fulfilled, (state, { payload }) => {
      state.reasons = payload;
      state.reasonsLoading = false;
    });
    builder.addCase(getReasons.pending, (state, action) => {
      state.reasonsLoading = true;
    });
    builder.addCase(getReasons.rejected, (state, action) => {
      state.reasonsLoading = false;
    });

    builder.addCase(publishRMA.fulfilled, (state, { payload }) => {
      const rma = payload;
      if (rma.rmaItems) {
        rma.rmaItems.forEach((item, i) => {
          if (item.status === 'review') {
            state.gatekeeping = true;
          }
        });
      }
      state.publishedRma = rma;
      state.publishLoading = false;
    });
    builder.addCase(publishRMA.pending, (state, action) => {
      state.publishLoading = true;
    });
    builder.addCase(publishRMA.rejected, (state, action) => {
      state.publishLoading = false;
    });
  },
});

export const { setWooOrderId, addRmaLineItem, setSubReason, setSelectedAction, setDefectBy, resetRma, setDescription, setImei } = rmaSlice.actions;
export default rmaSlice.reducer;
