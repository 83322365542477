import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './screens/main/';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import { Provider } from 'react-redux';
import { store } from './store/store';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import { LiveChatWidget } from '@livechat/widget-react';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
});

const theme = createMuiTheme({
  status: {
    danger: '#FF0E20',
  },
  palette: {
    primary: {
      main: '#049eea',
      dark: '#049eea',
      light: '#049eea',
    },
    secondary: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily: `"Open Sans", "Helvetica", "Arial", sans-serif`,
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      fontFamily: `"Gilroy", "Open Sans", "Helvetica", "Arial", sans-serif`,
    },
    h2: {
      fontFamily: `"Gilroy", "Open Sans", "Helvetica", "Arial", sans-serif`,
    },
    h3: {
      fontFamily: `"Gilroy", "Open Sans", "Helvetica", "Arial", sans-serif`,
    },
    h4: {
      fontFamily: `"Gilroy", "Open Sans", "Helvetica", "Arial", sans-serif`,
    },
    h5: {
      fontFamily: `"Gilroy", "Open Sans", "Helvetica", "Arial", sans-serif`,
    },
    h6: {
      fontFamily: `"Gilroy", "Open Sans", "Helvetica", "Arial", sans-serif`,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none', // Prevent text transformation for all buttons
        minWidth: '300px',
      },
    },
  },
});

ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18next}>
            <Main className="main-content" />
            <LiveChatWidget license="10552682" />
          </I18nextProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
