import React, { Component } from 'react';
import { Grid, Paper, Typography, Checkbox } from '@material-ui/core';
import './style.css';
import { setChecked } from '../../../../store/slices/orderSlice';
import { connect } from 'react-redux';
/**
 * Product display component.
 *
 * @version 1.0.0
 */
class ProductDisplay extends Component {

  /**
   * Constructor
   *
   * @param {Object} props - props
   * @version 1.0.0
   */
  constructor(props) {
    super(props);
    this.updateCheckbox = this.updateCheckbox.bind(this);
  }

  /**
   * Update checkbox
   *
   * @param {Object} inWarranty - Warranty.
   * @version 1.0.0
   */
  updateCheckbox(inWarranty) {
    if (inWarranty) {
      this.props.setChecked({ id: this.props.item.id, lineId: this.props.item.lineId });
    }
  }

  /**
   * Get the price and render is in a more human fashion;
   * @param {int} price the price
   * @return {string} rendered price
   */
  renderPrice(price) {
    let returnString = '€ ';
    if (price % 1 === 0) {
      returnString += price + ',-';
    } else {
      returnString += price.toString().replace('.', ',');
    }
    return returnString;
  }


  /**
   * Renderer
   *
   * @return {JSX} - the to render JSX.
   * @version 1.0.0
   */
  render() {
    const item = this.props.item;

    return (
      <React.Fragment>
        <Paper elevation={0} className={item.checked ? 'productPaper checked' : 'productPaper'} onClick={() => this.updateCheckbox(item.inWarranty)}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center" >
            <Grid
              item
              xs={1}>
              <Paper className="gridPaper" elevation={0}>
                {item.inWarranty && <Checkbox
                  id="item-0"
                  alignItems="center"
                  checked={item.checked}
                  color="default"
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                />}
              </Paper>
            </Grid>
            <Grid
              item
              xs={3} md={2}>
              <Paper className="gridPaper" elevation={0}>
                <img alt="productImage" className="image" src={item.imgUrl} />
              </Paper>
            </Grid>
            <Grid
              item
              xs={8} md={9}>
              <Paper className="productName" elevation={0}>
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  className="productGrid">
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Typography className="productTitle">{item.name}</Typography>
                    {item.serial ? <Typography>IMEI: {item.serial}</Typography> : null}
                    <Typography>{this.renderPrice(item.price)}</Typography>
                    {!item.inWarranty && <Typography className="warrantyNotice">Buiten garantieperiode</Typography>}
                    {!item.inRetractionPeriod && <Typography className="warrantyNotice">Buiten 14 dagen bedenktijd</Typography>}
                  </div>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.orders.order,
});

const mapDispatchToProps = { setChecked };

export default connect(mapStateToProps, mapDispatchToProps)(ProductDisplay);
