import React, { Component } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import './index.css';
import OrderSearch from '../../components/orderSearch';
import SelectProducts from '../../components/orderSearch/selectProducts';
import { connect } from 'react-redux';
import { resetOrder } from '../../store/slices/orderSlice';

// import OrderLineItems from '../../components/orderLineItems';

/**
 * RMA reasons screen
 *
 * @version 1.0.0
 */
class RegisterRMAScreen extends Component {

  /**
   * Constructor
   *
   * @param {Object} props - props
   * @version 1.0.0
   */
  constructor(props) {
    super();
    this.props = props;


    this.state = {
      order: props.order | {},
    };

    this.resetSearch = this.resetSearch.bind(this);
  }

  /**
   * Reset order.
   */
  resetSearch() {
    this.props.resetOrder();
  }

  /**
   * Renderer
   *
   * @return {JSX} - the to render JSX.
   * @version 1.0.0
   */
  render() {
    return (
      <React.Fragment>
        <Container>
          <Grid container justify={'center'}>
            <Grid item xs={12} lg={8}>
              <Typography variant="h6" gutterBottom id="apply-return" className="pageTitle">Retour aanmelden</Typography>
              <Typography variant="body1" gutterBottom>
                Eenvoudig je retour aanmelden. Je vindt je ordernummer (bestaat uit 6 of 7 cijfers) en e-mailadres waar je bestelling mee geplaatst is in je orderbevestigingsmail of via mijn account.
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Grid container justify={'center'}>
            <Grid item xs={12} lg={8}>
              { this.props.order && <Typography id="return-reason-back" className="backText" onClick={() => this.resetSearch()}>Terug</Typography>}
            </Grid>
            { !this.props.order &&<OrderSearch/>}
            { this.props.order && <SelectProducts order={this.props.order}/>}
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.orders.order,
});

const mapDispatchToProps = { resetOrder };
export default connect(mapStateToProps, mapDispatchToProps)(RegisterRMAScreen);
