import React, { Component } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import ProductDisplay from './productDisplay';
import './style.css';
import { connect } from 'react-redux';
import { nextPage } from '../../../store/slices/pageSlice';

/**
 * Select products component
 *
 * @version 1.0.0
 */
class SelectProducts extends Component {

  /**
   * Constructor
   *
   * @param {Object} props - props
   * @version 1.0.0
   */
  constructor(props) {
    super(props);
    this.checkOneChecked = this.checkOneChecked.bind(this);
    this.gotoNextPage = this.gotoNextPage.bind(this);
  }


  /**
   * Check if an item is checked.
   *
   * @return {Boolean} - true or false
   */
  checkOneChecked() {

    if (this.props.order.items !== undefined) {
      for (const item of this.props.order.items) {
        if (item.checked) {
          return true;
        }
      }
    }


    return false;
  }

  /**
   * Go to next page.
   */
  gotoNextPage() {
    this.props.nextPage();
  }


  /**
   * Renderer
   *
   * @return {JSX} - the to render JSX.
   * @version 1.0.0
   */
  render() {
    const checked = this.checkOneChecked();
    return (
      <React.Fragment>
        <Grid item container xs={12} lg={8} justify="space-between">
          <Grid item xs={12}>
            <Typography variant="h6" className="headText">Selecteer de items die je wilt retourneren</Typography>
            {this.props.order.items && this.props.order.items.map((item, i) => {
              return (
                <ProductDisplay key={i} item={item}/>
              );
            })}
            {!this.props.order.items.length && <Typography style={{color: '#ff0e20'}}>Alle producten van deze bestelling zijn al retour gestuurd.</Typography>}
            {this.props.order.items.length ? <Button id="continue" xs={4} variant="contained" className="confirmButton" onClick={this.gotoNextPage} disabled={!checked}>
              Doorgaan
            </Button> : ''}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.orders.order,
  page: state.page.page,
});

const mapDispatchToProps = { nextPage };

export default connect(mapStateToProps, mapDispatchToProps)(SelectProducts);
