import { createSlice } from '@reduxjs/toolkit';

const pageSlice = createSlice({
  name: 'page',
  initialState: {
    page: 0,
  },
  reducers: {
    nextPage(state, action) {
      state.page = state.page + 1;
    },
    setPage(state, action) {
      if (state.page > action.payload) {
        state.page = action.payload;
      }

    },
  },
});

export const { nextPage, setPage } = pageSlice.actions;
export default pageSlice.reducer;
